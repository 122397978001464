import Home from "./components/Home";
import GasEstacionario from "./components/GasEstacionario";
import Sucursales from "./components/Sucursales";
import Cilindros from "./components/Cilindros";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="" element={<Home />} />
        <Route exact path="/gas-estacionario" element={<GasEstacionario />} />
        <Route exact path="/sucursales" element={<Sucursales />} />
        <Route exact path="/cilindros" element={<Cilindros />} />
      </Routes>
    </>

  );
}

export default App;
