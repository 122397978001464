import React, { useEffect, useState } from "react";
import axios from "axios";
import omniaService from "../services/omnia";
import "../assets/css/gas-estacionario.css"
import logo from "../assets/img/perla-alt.png";
import icono1 from "../assets/img/gas-perla-icono-indicador.png";
import icono2 from "../assets/img/gas-perla-icono-rendimiento.png";
import icono3 from "../assets/img/gas-perla-icono-conveniencia.png";
import icono4 from "../assets/img/gas-perla-icono-ahorro.png";
import icono5 from "../assets/img/gas-perla-icono-impacto.png";
import icono6 from "../assets/img/gas-perla-icono-seguridad.png";
import img1 from "../assets/img/gas-perla-estacionario-img-01.jpg";
import footer from "../assets/img/gas-perla-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from "react-helmet";

const GasEstacionario = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 2) { error = true; msj = "El campo servicio es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            axios.post('https://omnia.futurite.com/api/save-lead-form', data)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="gas-estacionario">
            {/* META TAGS */}
            <Helmet>
                <title>Servicio de gas estacionario | Gas Perla </title>
                <meta name="description"
                    content="Descubre la confiabilidad y eficiencia con Gas Perla, tu proveedor experto en servicio de gas estacionario. Seguridad y calidad que impulsan tu día a día." />

                <meta property="og:title" content="Servicio de gas estacionario | Gas Perla" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://www.gasperla.com/gas-estacionario" />
                <meta property="og:site_name" content="Gas Perla" />
                <meta property="og:description"
                    content="Descubre la confiabilidad y eficiencia con Gas Perla, tu proveedor experto en servicio de gas estacionario. Seguridad y calidad que impulsan tu día a día." />

                <meta name="twitter:title" content="Servicio de gas estacionario | Gas Perla" />
                <meta name="twitter:description"
                    content="Descubre la confiabilidad y eficiencia con Gas Perla, tu proveedor experto en servicio de gas estacionario. Seguridad y calidad que impulsan tu día a día." />
                <meta name="twitter:card" content="summary" /> 
                <meta name="twitter:image" content={logo} />

                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                    {
                        "@context":"https://schema.org",
                        "@type":"FAQPage",
                        "mainEntity":[
                            {
                                "@type":"Pregunta ",
                                "nombre":" ¿Qué es el servicio de gas estacionario?",
                                "respuesta aceptada":{
                                    "@tipo":"Respuesta",
                                    "texto":"Es el servicio de gas a domicilio por medio del cual se suministra gas LP a un tanque estacionario en un hogar o negocio."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"¿Cómo es el proceso para solicitar el servicio de gas estacionario?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"El pedido se puede realizar por llamada, WhatsApp o aplicación para programar el servicio. Las unidades acuden a los domicilios, son monitoreadas hasta surtir el servicio y se entrega un ticket personalizado."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"¿El servicio de gas estacionario es seguro?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"Así es, debido a que al tener el tanque de forma fija en un sitio, se evita que este sea sacudido o tenga movimientos bruscos que pongan en riesgo su integridad."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"¿Qué hacer en caso de una fuga de gas?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"En caso de una fuga de gas, se debe cerrar inmediatamente la llave del tanque, ventilar el lugar, evitar contacto con corrientes eléctricas u otras fuentes de ignición, y una vez fuera del lugar donde ha ocurrido la fuga, llamar a los servicios de emergencia. ."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"¿Cómo puedo contactarme para solicitar el servicio?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"Para solicitar el servicio de gas a domicilio, solamente es necesario llamar al número: (33) 3151 0381 o bien llenar el formulario que se encuentra al final de esta página. Uno de nuestros asesores le responderá para realizar la contratación."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"¿Cómo puedo saber cuál es el tamaño adecuado del tanque de gas para mis necesidades?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"Lo más común para casa habitación son tanques de 120, 160 y 300 litros."
                                }
                            },
                            {
                                "@type":"Pregunta",
                                "nombre":"Cuando hago un pedido, ¿cuánto tiempo tomaría para que el servicio de gas estacionario sea entregado en mi hogar?",
                                "Respuestaaceptada":{
                                    "@type":"Respuesta",
                                    "texto":"El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades."
                                }
                            }
                        ]
                    }                    
                    `}
                </script>
            </Helmet>
            <Header />
            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <p className="mb-2 mt-4"> ¡Directo a tu hogar o negocio! </p>
                            <h1 className="fw-bold fs-50"> Servicio de gas estacionario </h1>
                            <p className="mt-5"> Realiza tu pedido de Gas LP </p>
                            <a href="#form" className="btn-cta mt-2 mb-4"> CONTÁCTANOS </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mb-5 mt-5 ff-lato">
                                El servicio de gas estacionario consiste en Gas LP almacenado en su forma líquida, a altas <br />
                                presiones, en un tanque especial de metal. Para solicitar gas a domicilio, es necesario contar con <br />
                                uno de estos tanques, los cuales vienen en una amplia gama de tamaños.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-18">Conoce los beneficios que tiene el servicio gas estacionario para tu hogar o negocio</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 align-items-center">
                                <img src={icono1} className="w-25" />
                                <div class="fs-15 ms-4 ">
                                    <p class="fw-bold">Indicador de nivel</p>
                                    <p class="mb-0 me-3">
                                        Los tanques estacionarios cuentan con un indicador que permite conocer la cantidad de gas restante,
                                        lo que hace fácil saber cuándo es momento de pedir gas a domicilio.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 me-3 align-items-center">
                                <img src={icono2} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Rendimiento</p>
                                    <p class="mb-0 me-3">
                                        Normalmente, los tanques de gas tienen una mayor capacidad que los cilindros,
                                        lo que se traduce en un mayor rendimiento.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 me-3 align-items-center">
                                <img src={icono3} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Conveniencia</p>
                                    <p class="mb-0 me-3">
                                        El servicio de gas a domicilio evita que los usuarios se tengan que trasladar con sus cilindros
                                        hasta una estación de gas LP para realizar una recarga, por lo que se vuelve algo más práctico y cómodo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 me-3 align-items-center">
                                <img src={icono4} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Ahorro de energía</p>
                                    <p class="mb-0 me-3">
                                        El gas estacionario ofrece un ahorro significativo de energía en comparación con otros combustibles convencionales.
                                        Gracias a su eficiencia y capacidad calorífica, es una excelente opción para negocios y hogares.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 me-3 align-items-center">
                                <img src={icono5} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Impacto en el medio ambiente</p>
                                    <p class="mb-0 me-3">
                                        Usar gas estacionario tiene un impacto positivo en el medio ambiente, debido a que el gas LP emite menos contaminantes durante su
                                        combustión, por lo que resulta en una opción más ecológica.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon fs-50 me-3 align-items-center">
                                <img src={icono6} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Seguridad</p>
                                    <p class="mb-0 me-3">
                                        Estamos comprometidos con el cumplimiento de altos estándares y protocolos en el manejo del gas LP, con el fin de mantener
                                        un servicio de gas a domicilio completamente seguro.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative text-center mt-5">
                        <div className="btn-box position-absolute translate-middle start-50">
                            <a href="#form" className="btn-cta">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>

            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-4 mt-md-0 mt-5 ff-lato fs-18"> Datos sobre el servicio de gas estacionario </h2>
                            <p className="mb-5 text-center">
                                <li> Los medidores de los tanques de gas marcan el porcentaje de llenado, no la cantidad de litros que hay. </li>
                                <li> Por seguridad, los tanques de Gas LP no deben llenarse más del 90%. </li>
                                <li> Los tanques estacionarios tienen una vida útil de 10 años. </li>
                                <li> Es recomendable solicitar el servicio de gas a domicilio al momento de que el medidor del tanque de gas baje del 10%. </li>
                                <li> Para conocer la cantidad en litros que el medidor marca en porcentaje, éste debe multiplicarse por la capacidad total del tanque. </li>
                                <li> Conocer la capacidad total del tanque de gas que se posee es de gran importancia. </li>
                                <li> Antes de llenar el tanque, es importante identificar el porcentaje de carga inicial, así como el final después del llenado. </li>
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <img src={img1} alt="servicio de gas estacionario-gas estacionario 1" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold mb-5 text-center">Preguntas frecuentes sobre nuestro servicio de gas a domicilio</h3>
                    <div class="accordion" id="accordionExample">
                        {/* Pregunta1 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Qué es el servicio de gas estacionario?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Es el servicio de <strong> gas a domicilio </strong> por medio del cual se suministra gas LP a un tanque estacionario en un hogar
                                    o negocio.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta2 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo es el proceso para solicitar el servicio de gas estacionario?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El pedido puede realizarse por llamada, WhatsApp o app para programar el servicio. Las unidades acuden a los
                                    domicilios, son monitoreadas hasta surtir el servicio y se entrega un ticket personalizado.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta3 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿El servicio de gas estacionario es seguro?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Así es, debido a que al tener el tanque de forma fija en un sitio, se evita que este sea sacudido o
                                    tenga movimientos bruscos que pongan en riesgo su integridad.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta4 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Qué hacer en caso de una fuga de gas?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    En caso de una fuga de gas, se debe cerrar inmediatamente la llave del tanque, ventilar el lugar, evitar contacto
                                    con corrientes eléctricas u otras fuentes de ignición, y una vez fuera del lugar donde ha ocurrido la fuga,
                                    llamar a los servicios de emergencia.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta5 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Cómo me puedo contactar para solicitar el servicio?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Para solicitar el servicio de <strong> gas a domicilio </strong> , solamente es necesario llamar al número: (33) 3151 0381
                                    o bien llenar el formulario que se encuentra al final de esta página. <br />
                                    Uno de nuestros asesores le responderá para realizar la contratación.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta6 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Cómo puedo saber cuál es el tamaño adecuado del tanque de gas para mis necesidades?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Lo más común para casa habitación son tanques de 120, 160 y 300 litros.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta7 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Cuando hago un pedido, ¿cuánto tiempo tomaría para que el servicio de gas estacionario sea entregado en mi hogar?
                                </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC12 */}
            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="mb-5">Obtén un suministro <br /> constante de Gas LP para <br /> tu hogar o negocio.</h2>
                            <img className="w-75" src={footer} alt="servicio de gas estacionario-gas estacionario 2" />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="text" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="text" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta fw-bold mt-3"> ¡CONTÁCTANOS! </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default GasEstacionario;