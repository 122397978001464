import waIcon from "../../assets/img/logo-wa.png";
import logoB from "../../assets/img/perla-alt-white.png";
import phone from "../../assets/img/logo-tel-blanco.png";

const Footer = () => {
    return (
        <>
            <div className="footer bg-dark-grey-01" id="footer">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-7 col-12 mb-md-0 mb-4">
                            <img src={logoB} className="logo" alt="" />
                        </div>
                        <div className="col-md col-6">
                            <p className="text-white ls-25 fs-12 fw-bold">OFICINAS</p>
                            <p className="text-grey fs-12">
                                Carr. Guadalajara - Tepic 17400 <br />
                                La Venta del Astillero <br />
                                Zapopan, Jal. <br />
                                México.
                            </p>
                        </div>
                        <div className="col-md col-6">
                            <p className="text-white ls-25 fs-12 fw-bold">CONTACTO</p>
                            <p className="fs-12">
                                <a href="tel:523331510381">+52 (33) 3151.0381</a><br />
                                <a href="mailto:pedidos@gaslaperla.com">pedidos@gaslaperla.com</a>
                            </p>
                            <a className="fs-12" href="https://www.gasperla.com/avisos-de-privacidad.pdf"
                                target="_blank" rel="noreferrer">
                                Avisos de Privacidad
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="mb-md-0 mb-3 text-grey fs-10 mt-5">Gas Perla. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright bg-dark-grey text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md">
                            <p className="mb-0 text-white fs-10">GAS PERLA FORMA PARTE DE <a
                                href="https://www.gruporg.com/">GRUPO RG</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sticky-btn-footer bg-white text-center mobile-item ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fw-bold fs-18 text-red">
                                ¡Solicita tu recarga de Gas LP Hoy!
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-6">
                            <a href="#" className="btn-cta wa d-flex align-items-center justify-content-center">
                                <img src={waIcon} className="icon-button me-2" alt="" />
                                <span>Envianos<br /> WhatsApp</span>
                            </a>
                        </div>
                        <div className="col-md-6 col-6">
                            <a href="#" className="btn-cta call d-flex align-items-center justify-content-center">
                                <img src={phone} className="icon-button me-2" alt="" />
                                <span>Llámanos</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Footer;